if(typeof window.onKeyPress !== "function") {
    let typingTimer = null;
    /**
     * Execute the callback when a key is pressed that changes the input of the field.
     *
     * @param event
     */
    window.onKeyPress = function (event) {
        let data = event.data;
        if(data.args === undefined) {
            data.args = {};
        }
        data.args.target = event.target;
        if (event.key === undefined
            || event.key.length === 1
            || event.key == 'Backspace'
            || event.key == 'Delete') {
            if(data.delay === undefined || data.delay <= 0) {
                data.callback(data.args);
            } else {
                clearTimeout(typingTimer);
                typingTimer = setTimeout(function() {
                    data.callback(data.args);
                }, data.delay);
            }
        }
    }
}
