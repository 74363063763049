let container = document.getElementById('overview');
if(container !== undefined) {
    window.initOverviewPager = function() {
        document.querySelectorAll('.pagination .page-item:not(.disabled) .page-link').forEach(function (el) {
            el.onclick = function(e) {
                e.preventDefault();
                if(container.dataset.page !== el.dataset.page) {
                    container.dataset.page = el.dataset.page;
                    updateOverview();
                }
            }
        });
    };
    window.updateOverviewResetPage = function() {
        container.dataset.page = '1';
        updateOverview();
    }
    window.updateOverview = function() {
        let page = container.dataset.page;
        let time = new Date().getTime();
        let params = {};
        let search = document.getElementById('filter-search').value;
        if(search) {
            params['search'] = search;
        }
        if (page > 1) {
            params['page'] = page;
        }

        container.dataset.lastUpdate = time;
        container.innerHTML = '<div class="d-flex justify-content-center"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>';

        let entries = Object.entries(params).map(function(v) {
            return v[0] + '=' + encodeURIComponent(v[1]);
        });
        window.history.replaceState({}, document.title, window.location.pathname + (entries.length > 0 ? '?' : '') + entries.join('&'));

        params['time'] = time;
        axios.get(container.dataset.url, {
            params: params
        })
            .then(function (response) {
                if (response.data.time >= container.dataset.lastUpdate) {
                    container.innerHTML = response.data.html;
                    initOverviewPager();
                }
            })
            .catch(function(response) { });
    }
    document.querySelectorAll('.filter-text:not(.skip)').forEach(function (e) {
        const callback = function(e) {
            e.data = {
                callback: updateOverviewResetPage,
                delay: 200
            };
            onKeyPress(e);
        }
        e.onkeyup = callback;
        e.ontouchend = callback;
    });

    initOverviewPager();
}
