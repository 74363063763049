window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import './validation';
import './onkeypress';
import './cards';
import './modal';
import './phone';
import './autogrow';
import './sponsors';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/collapse';

const links = document.querySelectorAll('.js-link');
links.forEach(function(link) {
    link.addEventListener('click', function(e) {
        if(e.target.nodeName.toLowerCase() === 'a'
            || e.target.nodeName.toLowerCase() === 'button'
            || (
                e.toElement !== null && (
                    e.toElement.nodeName.toLowerCase() === 'a'
                    || e.toElement.nodeName.toLowerCase() === 'button'
                )
            )) {
            return;
        }
        window.location = link.dataset.href;
    })
});
