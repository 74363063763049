let container = document.getElementById('sponsors');
if(container !== undefined) {
    setInterval(function() {
        axios.get('/ajax/sponsors')
            .then(function (response) {
                container.innerHTML = response.data;
            })
            .catch(function(response) { });
    }, 10000);
}
